.contact {
  position: relative;
  top: 50px;
  opacity: 0;
  transition: all 1s;
  text-align: center;
  margin-bottom: $padding-y * 10;
  &.scrolled {
    top: 0;
    opacity: 1;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: $padding-y * 20;
  }
  .title {
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      font-size: 25px;
    }
  }
  p {
    font-size: 14px;
    font-weight: 300;
    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }
}