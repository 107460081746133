#ef_set {
  .banner {
    .image {
      @include media-breakpoint-down(sm) {
        &::before {
          background: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  .flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .image {
      flex-basis: 100%;
      @include media-breakpoint-up(lg) {
        flex-basis: 48%;
      }
      img {
        width: auto;
      }
    }
  }

  .gallery {
    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
    img {
      display: block;
      margin-bottom: 50px;
      &:nth-child(1) {
        margin-bottom: 100px;
        @include media-breakpoint-up(lg) {
          margin-bottom: 150px;
        }
      }
      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          margin-right: auto;
        }
        @include media-breakpoint-up(xl) {
          margin-bottom: 0;
        }
      }
      &:nth-child(3) {
        @include media-breakpoint-up(lg) {
          margin-left: auto;
        }
        @include media-breakpoint-up(xl) {
          position: relative;
          top: -50px;
          margin-bottom: 0;
        }
      }
      &:nth-child(4) {
        @include media-breakpoint-up(lg) {
          margin-right: auto;
        }
        @include media-breakpoint-up(xl) {
          position: relative;
          top: -250px;
          margin-bottom: 0;
        }
      }
      &:nth-child(5) {
        @include media-breakpoint-up(lg) {
          margin-left: auto;
          margin-right: auto;
        }
        @include media-breakpoint-up(xl) {
          position: relative;
          top: -150px;
          margin-bottom: 0;
        }
      }
    }
    .image {
      text-align: center;
      margin-bottom: 50px;
      position: relative;

      button {
        display: flex;
        align-items: center;
        padding: 0;
        border: none;
        color: $white;
        background-color: transparent;
        font-size: 32px;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 50px;
        margin-left: 20px;
        @include media-breakpoint-up(sm) {
          margin-left: calc((100vw - $sm-max-width) / 2 + 20px);
        }
        @include media-breakpoint-up(md) {
          margin-left: calc((100vw - $md-max-width) / 2 + 20px);
        }
        @include media-breakpoint-up(lg) {
          margin-left: calc((100vw - $lg-max-width) / 2 + 20px);
        }
        @include media-breakpoint-up(xl) {
          margin-top: 100px;
          margin-left: calc((100vw - $xl-max-width) / 2 + 20px);
        }
        @include media-breakpoint-up(xxl) {
          margin-left: calc((100vw - $xxl-max-width) / 2 + 20px);
        }
        img {
          margin-right: 20px * 2;
          margin-bottom: 0;
        }
      }
    }

  }
}