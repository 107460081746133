@keyframes icon {
  0%   {top:0px;}
  33%  {top:-50px;}
  66%  {top:-50px;}
  100% {top:0px;}
}

// @keyframes arrow-left {
//   0% {left: 0px;}
//   25% {left: -5px;}
//   75% {left: 5px;}
//   100% {left: 0px;}
// }

#resume {
  position: relative;
  margin-top: $padding-y * 15;
  @include media-breakpoint-up(lg) {
    margin-top: $padding-y * 20;
  }
  @include media-breakpoint-up(lg) {
    &.scrolled {
      .flexbox {
        .image {
          img {
            animation-name: icon;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
          }
          &::before {
            opacity: 1;
            right: -32%;
            top: 50px;
          }
          &::after {
            opacity: 1;
            right: -15%;
            top: 0;
          }
        }
      }
    }
  }

  #intro {
    margin-bottom: $padding-y * 5;
  }
  .backBtn {
    img {
      position: relative;
      left: 0;
      animation-name: arrow-left;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  #email {
    position: absolute;
    bottom: $padding-y;
    left: 20px;
    z-index: 1;
    img {
      width: 20px;
    }
  }

  h2 {
    margin-top: 0;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: $padding-y * 5;
    color: $pink;
    position: relative;
    @include media-breakpoint-up(lg) {
      font-size: 25px;
      margin-bottom: $padding-y * 7;
    }
    &::after {
      content: "";
      width: 1.5em;
      border-top: 1px solid $dark;
      position: absolute;
      top: 150%;
      left: 0;
      @include media-breakpoint-up(lg) {
        width: 2.5em;
        top: 200%;
      }
    }
    &.name {
      font-size: 25px;
      font-weight: 800;
      color: $black;
      @include media-breakpoint-up(lg) {
        margin-bottom: $padding-y * 5;
        font-size: 70px;
        line-height: 78px;
      }
      &::after {
        content: "";
        width: 1em;
        border-top: 1px solid $dark;
        position: absolute;
        top: 115%;
        left: 0;
      }
    }
  }
  h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
  }

  .btn {
    text-transform: uppercase;
  }
  .backBtn {
    display: none;
    position: relative;
    margin-bottom: $padding-y * 10;
    @include media-breakpoint-up(lg) {
      display: block;
      margin-bottom: $padding-y * 15;
    }
    @media (min-width: 1600px) {
      left: -100px;
    } 
  }
  .section {
    margin-bottom: $padding-y * 10;
    @include media-breakpoint-up(lg) {
      margin-bottom: $padding-y * 15;
    }
  }

  #experience {
    padding-bottom: $padding-y * 5;
    margin-bottom: $padding-y * 10;
    border-bottom: 1px solid $lightgrey-200;
    @include media-breakpoint-up(lg) {
      padding-bottom: $padding-y * 10;
      margin-bottom: $padding-y * 15;
    }
    .box {
      &:not(:last-child) {
        margin-bottom: $padding-y *5;
        padding-bottom: $padding-y *2;
        border-bottom: 1px solid $lightgrey-200;
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }
      p {
        margin-bottom: $padding-y * 3;
      }
    }
  }
  #education {
    padding-bottom: $padding-y * 10;
    @include media-breakpoint-up(lg) {
      padding-bottom: $padding-y * 15;
    }
    .box {
      &:not(:last-child) {
        margin-bottom: $padding-y *5;
      }
    }
    p {
      margin-bottom: 0;
    }
  }

  .position {
    margin-bottom: $padding-y * 3;
    svg {
      font-size: 5px;
      color: $lightgrey-200;
      vertical-align: middle;
      margin: 0 10px;
    }
  }

  .flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .image {
      background-color: $lightgrey-100;
      display: flex;
      align-items: flex-end;
      flex-basis: 35%;
      position: relative;
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: flex-end;
        flex-basis: 25%;
      }
      img {
        position: relative;
        top: 0;
        transition: top 0.5s linear;
      }
      @include media-breakpoint-up(lg) {
        &::before {
          content: "";
          background-image: url("../assets/images/icons/K_pink.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: bottom;
          position: absolute;
          top: 100px;
          right: -100%;
          opacity: 0;
          transition: all 0.5s ease 1s;
          // max-width: 100px;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        &::after {
          content: "";
          background-image: url("../assets/images/icons/K_trans.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: bottom;
          position: absolute;
          top: 50px;
          right: 100%;
          opacity: 0;
          transition: all 0.5s;
          width: 100%;
          height: 100%;
          // max-width: 100px;
        }
      }

    }
    .content {
      flex-basis: 60%;
      p {
        margin-bottom: 0;
      }
    }
    .box {
      flex-basis: 100%;
      @include media-breakpoint-up(lg) {
        flex-basis: 30%;
      }
    }
  }

}