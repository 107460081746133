@use "sass:map";
@import "font";
@import "variable";
@import '~bootstrap/scss/bootstrap.scss';
@import "./components/backButton.scss";
@import "./components/backToTopButton.scss";
@import "./components/header/header.scss";
@import "./components/footer/footer.scss";
@import "./components/otherProjectButton.scss";
@import "./components/contact.scss";
@import "./components/webForm.scss";
@import "./pages/badgeDesign.scss";
@import "./pages/boothDesign.scss";
@import "./pages/brandIdentity.scss";
@import "./pages/digital.scss";
@import "./pages/efSet.scss";
@import "./pages/home.scss";
@import "./pages/myStory.scss";
@import "./pages/print.scss";
@import "./pages/projectsOverview.scss";
@import "./pages/reHome.scss";
@import "./pages/resume.scss";
@import "./pages/webDesign.scss";
@import "./pages/fitsio.scss";
@import "./pages/letsTalk.scss";

@mixin make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}

body {
  overflow-x: hidden;
}

.btn {
  @include media-breakpoint-down(lg) {
    font-size: 12px;
  }

  &.btn-outline-dark {

    &:hover,
    &:focus,
    &:active {
      background-color: $pink;
      border-color: $pink;
    }
  }
}

// button, .btn, .btn.btn-light {
//   text-transform: uppercase;
//   border: 1px solid $black;
//   border-radius: 30px;
//   font-size: 12px;
//   font-weight: 500;
//   padding: 5px 30px;
//   background-color: transparent;
//   @include media-breakpoint-up(lg) {
//     border: 1px solid $black;
//     font-size: 14px;
//     font-weight: 500;
//     padding: 10px 50px;
//   }
//   &:hover, &:focus, &:active {
//     background-color: $pink;
//     border-color: $pink;
//     color: $white;
//   }
// } 

// .btn.btn-outline-light {
//   padding: 0;
//   border-color: transparent;
//   &:hover, &:focus, &:active {
//     background-color: transparent;
//     border-color: transparent;
//     color: transparent;
//   }
// }

p {
  line-height: 1.4;
  font-weight: 300;
  font-family: 'Nunito', 'Montserrat', sans-serif;
  margin-bottom: 30px;
  font-size: 14px;
}

ul,
ol {
  font-family: 'Crimson Pro';
  font-weight: 300;
  li {
    &:hover {
      color: $primary;
      // opacity: 1;
    }
  }
}

img {
  width: auto;
  max-width: 100%;
}

.slick-arrow {
  &.slick-prev {
    &::before {
      content: "\25C0";
      color: $black;
    }
  }

  &.slick-next {
    &::before {
      content: "\25B6";
      color: $black;
    }
  }
}

.slick-slider {
  .slick-dots {
    li {
      margin: 0;

      button {
        &::before {
          font-size: 10px;
        }
      }
    }
  }
}