.back-to-top {
  position: fixed;
  bottom: $padding-y * 2;
  right: 20px;
  z-index: 50;
  cursor: pointer;
  img {
    max-height: 44px;
  }
  svg {
    width: 30px;
    height: 30px;
    padding: $padding-y;
    color: $pink;
    border-radius: 50%;
    text-align: center;
    z-index: 100;
    &:hover {
      background-color: $pink;
      color: $white;
      box-shadow: none;
    }
    @include media-breakpoint-up(lg) {
      width: 40px;
      height: 40px;
      padding: 20px;
    }
  }
}

#backToTopButton {
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  color: $black;
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  &:hover {
    color: $pink;
  }
}