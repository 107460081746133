#web_design {
  .banner {
    .image {
      @include media-breakpoint-down(sm) {
        &::before {
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.105) 0%, rgba(0, 0, 0, 0.3) 100%);
        }
      }
    }
  }

  .flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $padding-y * 10;
    @include media-breakpoint-up(lg) {
      margin-bottom: $padding-y * 15;
    }

    &.two {
      .image {
        @include media-breakpoint-up(lg) {
          flex-basis: 48%;
        }
      }
    }

    .image {
      flex-basis: 100%;
      text-align: center;
      &:not(:last-child) {
        margin-bottom: $padding-y * 5;
      }
      @include media-breakpoint-up(lg) {
        flex-basis: 32%;
      }
      img {
        width: auto;
      }
    }
  }

  .bg {
    @include media-breakpoint-up(lg) {
      padding-top: $padding-y * 15;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: $gray-300;
        z-index: -1;
      }
    }
  }
}