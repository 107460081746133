#digital {
  @include media-breakpoint-up(xl) {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .section {
    margin-bottom: 100px;

    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        margin-bottom: 250px;
      }
    }
  }

  .banner {
    margin-bottom: 100px;

    .image {
      &::before {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .name {
    position: relative;
    margin-bottom: 40px;
    font-size: 20px;
    text-align: center;
    margin-top: 50vh;
    font-weight: 700;

    &::after {
      content: "";
      position: absolute;
      top: calc(100% + 20px);
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 40px;
      border: 1px solid $black;
    }
  }

  .summary {
    font-size: 14px;
    font-weight: 300;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 200px;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 100px;
    transition: all 1s ease;

    &:hover {
      color: rgba(0, 0, 0, 0.1);
      letter-spacing: 0.05em;
    }
  }

  .flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .box {
      &:first-child {
        flex-basis: 48%;
      }

      &:last-child {
        flex-basis: 40.4%;
      }

      .wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;

        img {
          max-width: 48%;

          @include media-breakpoint-up(lg) {
            max-width: 49%;
          }
        }

        +img {
          margin-bottom: 30px;

          @include media-breakpoint-up(lg) {
            margin-bottom: 60px;
          }
        }
      }
    }
  }

  .info {
    &.scrolled {
      .name, .summary {
        top: 0;
        opacity: 1;
      }
    }

    .name {
      position: relative;
      top: 300px;
      opacity: 0;
      transition: all 1s ease;
    }

    .summary {
      position: relative;
      top: 300px;
      opacity: 0;
      transition: all 1s ease 0.3s;
    }
  }

  img {
    // cursor: pointer;
  }
}