.project {
  .banner {
    position: relative;
    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 150px;
    }

    .image {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      img {
        max-height: 100vh;
        height: 100svh;
        width: 100%;
        object-fit: cover;

        &.mobile {
          display: block;

          @include media-breakpoint-up(sm) {
            display: none;
          }
        }

        &.desktop {
          display: none;

          @include media-breakpoint-up(sm) {
            display: block;
          }
        }
      }

    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 0 20px;
      margin: auto;
      width: fit-content;
      height: fit-content;
      text-align: center;
      font-size: 36px;
      font-weight: 800;
      color: $white;
      @include media-breakpoint-up(lg) {
        font-size: 50px;
      }
      .name {
        position: relative;
        width: fit-content;
        margin-bottom: 40px;
        font-size: 20px;
        &::after {
          content: "";
          position: absolute;
          top: calc(100% + 20px);
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 40px;
          border: 1px solid $white;
        }
      }
      .summary {
        font-size: 14px;
        font-weight: 300;
        max-width: 400px;
      }
    }

    .content {
      padding: 20px;
      color: $white;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @include media-breakpoint-up(lg) {
        padding-bottom: 50px;
      }

      .keypoint {
        display: flex;

        div {
          line-height: 1.5;
          font-size: 12px;

          @include media-breakpoint-up(sm) {
            font-size: 16px;
          }

          &:nth-child(2) {
            margin: 0 20px;

            @include media-breakpoint-up(sm) {
              margin: 0 80px;
            }
          }

          span {
            &::before {
              content: "\a";
              white-space: pre;

              @include media-breakpoint-up(sm) {
                white-space: normal;
              }
            }
          }
        }
      }

      .icons {
        display: flex;
        flex-direction: column;
        min-width: 30px;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }

        img {
          width: 30px;
          height: 30px;
        }

        @include media-breakpoint-up(lg) {
          img {
            width: 45px;
            height: 45px;
          }
        }
      }
    }
  }

  h2 {
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 10px;
    @include media-breakpoint-up(lg) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 14px;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }

  ol,
  ul {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 50px;
    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }

  .btn {
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      padding: $padding-y 20px * 5;
    }
  }

  .video {
    margin-bottom: 100px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 150px;
    }

    img {
      width: auto;
      max-width: 100%;
    }
    video {
      width: auto;
      max-width: 100%;
      max-height: 800px;
    }
  }

  .image {
    img {
      width: 100%;
    }
  }

  .center {
    text-align: center;
  }

  .project-intro {
    .section {
      margin-bottom: 50px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 100px;
      }
    }
  }
  .section {
    margin-bottom: 50px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 150px;
    }
  }

  .bg {
    background-color: $gray-400;
    padding: 100px 0;

    @include media-breakpoint-up(lg) {
      padding: 150px 0;
    }
  }
  .button-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
}

#badge_design {
  .banner {
    .image {
      @include media-breakpoint-down(sm) {
        &::before {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
        }
      }
    }
  }

  img {
    margin-bottom: 100px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 150px;
    }
  }
  #award_page {
    width: 500px;
  }
}