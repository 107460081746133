#booth_design {
  .banner {
    .image {
      @include media-breakpoint-down(sm) {
        &::before {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        }
      }
    }
  }

  .center {
    img:not(:last-child) {
      margin-bottom: 100px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 150px;
      }
    }
  }

  .flexbox_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 100px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 150px;
    }

    @include media-breakpoint-up(xl) {
      justify-content: space-evenly;
    }

    .flexbox {
      &:first-child {
        flex-basis: 66%;
      }

      &:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        flex-basis: 32%;
      }

      @include media-breakpoint-up(xl) {
        &:first-child {
          flex-basis: auto;
        }

        &:nth-child(2) {
          flex-basis: 30%;
        }
      }

      img {
        margin-bottom: 0px;
      }
    }
  }

  .slick-slide {
    // width: 100%;
    // @include media-breakpoint-up(sm) {
    //   width: $sm-max-width;
    // }

    // @include media-breakpoint-up(md) {
    //   width: $md-max-width;
    // }

    // @include media-breakpoint-up(lg) {
    //   width: $lg-max-width;
    // }

    // @include media-breakpoint-up(xl) {
    //   width: $xl-max-width;
    // }
    
    h2 {
      text-align: center;
    }

    img {
      object-fit: contain;
      @include media-breakpoint-up(sm) {
        width: 100%;
        height: 400px;
        text-align: center;
      }
      @include media-breakpoint-up(md) {
        height: 500px;
      }
   
      @include media-breakpoint-up(xl) {
        height: 700px;
      }
    }
  }

  #book-cover {
    width: 500px;
  }
}