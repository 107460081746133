@keyframes icon {
  0%   {top:0px;}
  33%  {top:-50px;}
  66%  {top:-50px;}
  100% {top:0px;}
}
@keyframes arrow-top {
  0% {top: 0px;}
  25% {top: -10px;}
  75% {top: 10px;}
  100% {top: 0px;}
}

@keyframes rotation {
  0%   {transform: rotate(0);}
  100%  {transform: rotate(360deg);}
}
$center-x: 50%;
$center-y: 90%;
$offset-x: 150px;
$offset-y: calc(100% - $center-y);
$offset-x-lg: 250px;
$offset-y-lg: calc(100% - $center-y);
$offset-x1: 50vw;
$offset-y1: 100vh;
#home {
  overflow-x: hidden; 

  #greeting {
    margin-top: 150px;

    &.scrolled {

      .flexbox {
        .content {
          bottom: 30vh;
          .title {
            color: $white;
          }
          .intro {
            color: $white;
            .btn {
              color: $white;
              border-color: $white;
              font-size: inherit;
            }
          }
        }
      }
    }

    >div {
      position: relative;
      .flexbox {

        @include media-breakpoint-up(lg) {
          display: flex;
          justify-content: space-between;
        }
        .content {
          @include media-breakpoint-up(lg) {
            flex-basis: 50%;
          }
          @include media-breakpoint-up(xl) {
            margin-left: auto;
            max-width: 350px;
          }
     
          .title {
            font-weight: 900;
            font-size: 50px;
            font-family: 'Crimson Pro';
            margin-bottom: 20px;
            line-height: 1;
 
            span::before {
              content: "\a";
              white-space: pre;
            }
          }
          .intro {
            font-weight: 500;
            font-size: 15px;
            margin-bottom: 30px;
            @include media-breakpoint-up(lg) {
              margin-bottom: 40px;
              font-size: 18px;
            }
            >span {
              vertical-align: middle;
            }
            .btn {
              margin: 5px;
              min-width: 120px;
              font-style: italic;
              font-family: 'Crimson Pro';
              font-weight: 500;
              font-size: inherit;
              @include media-breakpoint-up(lg) {
                margin: 5px 10px;
              }
            }
          }
        }
        .image {
          text-align: center;
          @include media-breakpoint-up(lg) {
            position: relative;
            z-index: 1;
            flex-basis: 50%;
            min-height: 430px;
            display: flex;
            justify-content: end;
            align-items: end;
          }
          img {
            @include media-breakpoint-down(lg) {
              max-width: 200px;
            }
            @include media-breakpoint-up(lg) {
              width: 300px;
            }
          }
        }
  
      }
  
      .figure {
        display: flex;
        flex-wrap: wrap;
        width: fit-content;
        background-color: #e1e2df;
        border-radius: 20px;
        padding: 20px 30px;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-up(lg) {
          position: absolute;
          bottom: 50px;
          left: 0;
          padding-right: 200px;
        }
        @include media-breakpoint-up(xl) {
          left: 140px;
        }
    
        .box {
          padding: 0 10px;
          .number {
            font-weight: 800;
            font-size: 30px;
          }
          .title {
            font-size: 12px;
            @include media-breakpoint-up(md) {
              font-size: 16px;
            }
          }
          .logo {
            img {
              max-height: 40px;
            }
          }
        }
      }
    }
  }
  .brand-logo {
    padding: 100px 0;
    background-color: $black;
    margin-bottom: 100px;
    .title {
      text-align: center;
      color: $white;
      margin-bottom: 50px;
      font-size: 20px;
    }
    .flexbox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 10px;
      @include media-breakpoint-up(md) {
        row-gap: 13px;
      }
      .image {
        display: flex;
        flex-basis: 49%;
        align-items: center;
        justify-content: center;
        padding: 30px;
        border: 1px solid rgba(255, 255, 255, 0.25);

        @include media-breakpoint-up(lg) {
          flex-basis: 24%;
        }
        img {
          object-fit: contain;
          max-height: 100px;
        }
      }
    }
  }
  .quick_link {
    display: none;
    @include media-breakpoint-up(sm) {
      left: $container-padding-x * 0.5;
    }
    @include media-breakpoint-up(xl) {
      display: block;
      position: fixed;
      top: 60vh;
      left: 100px;
      z-index: 1;
    }
    @media(min-width: 1500px) {
      left: 8vw;
    }
    ul {
      @include media-breakpoint-up(xl) {
        font-style: italic;
        color: #515151;
        list-style-type: none;
        li {
          &:hover {
            &::before {
              content: "|";
              font-style: normal;
              margin-right: 1em;
            }
          }
          margin-bottom: 10px;
          a {
            color: inherit;
            text-shadow: 0 0 $white;
          }
        }
      }
    }
  }
  
  #projects {
    margin-bottom: 100px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      max-width: $md-max-width;
      margin-left: auto;
      margin-right: auto;
      padding-right: $grid-gutter-width * 0.5;
      padding-left: $grid-gutter-width * 0.5;
    }
    @include media-breakpoint-up(lg) {
      max-width: $lg-max-width;
    }
    @include media-breakpoint-up(xl) {
      max-width: 900px;
    }
    .project {
      position: relative;
      @include media-breakpoint-up(md) {
        margin-bottom: 150px;
      }
      &.scrolled {
        .image {
          left: 0;
          opacity: 1;
        }
        .content {
          left: 0;
          opacity: 1;
        }
        @include media-breakpoint-up(md) {
          .image {
            left: 0;
            opacity: 1;
          }
          .content {
            left: 45%;
            opacity: 1;
          }
        }
    
        &:nth-of-type(2n-1) {
          @include media-breakpoint-up(md) {
            .image {
              left: 50%;
            }
            .content {
              left: 0;
            }
          }
        }
      }

      &:nth-of-type(2n-1) {
        @include media-breakpoint-up(md) {
          .image {
            left: 60%;
          }
          .content {
            left: -10%;
          }
        }
      }

      .image {
        position: relative;
        text-align: center;
        left: -20%;
        opacity: 0;
        transition: all 0.5s;
        @include media-breakpoint-up(md) {
          width: 50%;
          left: -10%;
          opacity: 0;
          transition: all 0.5s;
        }
      }
      .content {
        position: relative;
        flex-basis: 100%;
        background-color: $gray-100;
        left: 20%;
        opacity: 0;
        transition: all 0.5s linear 0.5s;
        box-shadow: 0px 4px 4px 0 rgba(0,0,0,0.25);
        @include media-breakpoint-up(md) {
          width: 55%;
          height: fit-content;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 55%;
          opacity: 0;
          margin-top: auto;
          margin-bottom: auto;
          transition: all 0.5s;
        }
  
        .wrapper {
          padding-top: 40px;
          padding-bottom: 100px;
          @include media-breakpoint-up(md) {
            padding: 20px 40px 40px;
          }
          @include media-breakpoint-up(xl) {
            padding: 40px;
          }
       
          .hashtag {
            word-break: break-all;
            font-size: 10px;
            line-height: 1.8;
            color: #9A9A9A;
            margin-bottom: 50px;
            font-weight: 500;
  
            span {
              margin-right: 20px;
            }
          }
          .title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 50px;
            position: relative;
            line-height: 34px;
            &::after {
              content: "";
              width: 4em;
              border-top: 1px solid $dark;
              position: absolute;
              top: calc(100% + 20px);
              left: 0;
            }
          }
          p {
            margin-bottom: 40px;
            font-size: 14px;
            font-weight: 300;
          }
          .arrow {
            width: 45px;
          }
        }
      }
    }
  }
  #product_archived {
    font-family: "Crimson Pro";
    font-style: italic;
    font-weight: 700;
    margin-bottom: 100px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 200px;
    }
    .title {
      font-size: 14px;
      text-align: center;
      margin-bottom: 30px;
      @include media-breakpoint-up(lg) {
        font-size: 20px;
        margin-bottom: 50px;
      }
    }
  }
  .message {
    font-size: 14px;
    font-family: 'Crimson Pro';
    font-weight: 700;
    text-align: center;
    margin-bottom: $padding-y * 10;
    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }
    >span {
      vertical-align: middle;
    }
    .btn {
      font-size: inherit;
      margin-left: 5px;
      font-style: italic;
      font-family: 'Crimson Pro';
      font-weight: 500;
      min-width: 100px;
      margin-top: 5px;
      margin-bottom: 5px;
      @include media-breakpoint-up(lg) {
        min-width: 160px;
        margin-left: 10px;
      }
    }
  }
  .email {
    position: relative;
    width: fit-content;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(lg) {
      margin-bottom: 200px;
    }
    .content {
      position: absolute;
      top: 43%;
      left: 38%;
      color: $primary;
      font-weight: 700;
      font-family: 'Crimson Pro';
      font-style: italic;
      font-size: 14px;
      @include media-breakpoint-up(lg) {
        top: 42%;
        left: 37%;
        font-size: 20px;
      }
    }
    img {
      animation: rotation 10s linear infinite;
      max-width: 150px;
      @include media-breakpoint-up(lg) {
        max-width: 200px;
      }
    }
  }
}

