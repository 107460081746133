#print {
  @include media-breakpoint-up(xl) {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .section {
    margin-bottom: 100px;
    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        margin-bottom: 250px;
      }
    }
  }
  .banner {
    margin-bottom: 100px;
    .image {
      &::before {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .name {
    position: relative;
    margin-bottom: 40px;
    font-size: 20px;
    text-align: center;
    margin-top: 50vh;
    font-weight: 700;

    &::after {
      content: "";
      position: absolute;
      top: calc(100% + 20px);
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 40px;
      border: 1px solid $black;
    }
  }

  .summary {
    font-size: 14px;
    font-weight: 300;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 200px;
  }

  img {
    margin-bottom: 20px;
  }

  h2 {
    font-size: 14px;
    text-align: center;
    transition: all 1s ease;
    &:hover {
      color: rgba(0,0,0,0.1);
      letter-spacing: 0.05em;
    }
  }
  .description {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    font-weight: 300;
    max-width: 400px;
    margin-bottom: 100px;
    p {
      margin-left: auto;
      margin-right: auto;
      font-size: 14px;
      font-weight: 300;
      max-width: 400px;
      margin-bottom: 15px;
    }
  }
  .flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    img {
      object-fit: contain;
      width: 50%;
    }
    &.custom {
      img {
        width: 49%;
        object-fit: cover;
        // margin-bottom: 0;
      }
      .vert {
        width: 49%;
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
        }
      }
    }
  }

  .info {
    &.scrolled {
      .name, .summary {
        top: 0;
        opacity: 1;
      }
    }

    .name {
      position: relative;
      top: 300px;
      opacity: 0;
      transition: all 1s ease;
    }

    .summary {
      position: relative;
      top: 300px;
      opacity: 0;
      transition: all 1s ease 0.3s;
    }
  }
}