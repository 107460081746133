$height-of-content: 48px;
#re-home {
  .banner {
    .image {
      @include media-breakpoint-down(sm) {
        &::before {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
        }
      }
    }

    .title {
      img {
        width: 53px;
      }
      span {
        margin-left: 20px;
        vertical-align: middle;
        @include media-breakpoint-down(lg) {
          display: block;
          margin-bottom: 20px;
        }
      }
    }
  }

  .section {
    .list_title {
      font-size: 14px;
      text-decoration: underline;
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }
    }
  }

  .flexbox {
    background-color: rgba(231, 238, 239, 1);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-between;
    height: 1350px;
    padding: 40px;
    margin-bottom: 30px;
    position: relative;
    @media (min-width: 325px) {
      height: 1450px;
    }
    @media (min-width: 375px) {
      height: 1550px;
    }
    @media (min-width: 425px) {
      height: 1700px;
    }
    @media (min-width: 475px) {
      height: 1800px;
    }
    @media (min-width: 525px) {
      height: 1950px;
    }
    @include media-breakpoint-up(sm) {
      padding: 50px;
      height: 1850px;
    }

    @include media-breakpoint-up(md) {
      height: 2350px;
    }

    @include media-breakpoint-up(lg) {
      height: 1600px;
    }

    @include media-breakpoint-up(xl) {
      height: 1900px;
    }
    .content {
      font-weight: 600;
      max-width: 45%;
      margin-bottom: 50px;
      font-size: 14px;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
        position: absolute;
        width: 50%;
        max-width: 400px;
        margin-bottom: 0;
        top: 50px;
        left: 50px;
        &.shift {
          top: 850px;
          max-width: 200px;
        }
      }

      @include media-breakpoint-up(xl) {
        max-width: 550px;
        &.shift {
          top: 1000px;
        }
      }
    }

    .image {
      text-align: start;
      max-width: 45%;
      flex-basis: auto;
      margin-bottom: 100px;

      @include media-breakpoint-up(lg) {
        max-width: 30%;
        flex-basis: auto;
        margin-bottom: 150px;
        &#one {
          margin-top: 250px;
          margin-bottom: calc(150px + $height-of-content);
        }
        &#three {
          margin-top: 150px;
        }
      }

      @include media-breakpoint-up(xl) {
        &#one {
          margin-top: 300px;
        }

        &#three {
          margin-top: 150px;
        }
      }

      img {
        width: auto;
      }
    }
  }

  #certs {
    .image {
      img {
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}