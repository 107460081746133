#footer {
  background-color: $gray-700;
  color: $white;
  padding: 40px 20px; 
  font-size: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  text-align: center;
  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
  img {
    width: 16px;
    height: 16px;
    margin: 0 10px;
    animation: beating 0.5s linear infinite alternate;
  }
}

@keyframes beating {
  0%   {transform: scale(1,1);}
  100% {transform: scale(1.2,1.2);}
}