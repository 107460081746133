.header {
  background: $white;
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 100;
}
#logo {
  position: fixed;
  top: $container-padding-x * 0.5;
  left: $container-padding-x * 0.5;
  z-index: 10;

  @include media-breakpoint-up(sm) {
    top: -50px;
    // left: 100px;
    opacity: 0;
    transition: all 0.5s ease-out 0.5s;
  }

  @include media-breakpoint-up(xl) {
    left: 100px;
  }

  @media(min-width: 1500px) {
    left: 8vw;
  }

  &.scrolled {
    top: 30px;

    @include media-breakpoint-up(sm) {
      top: 40px;
      opacity: 1;
    }

    @include media-breakpoint-up(xl) {
      top: 55px;
    }
  }

  img {
    width: 150px;

    @include media-breakpoint-up(xl) {
      width: 200px;
    }
  }
}

#menu {
  position: fixed;
  top: 25px;
  right: $container-padding-x * 0.5;
  z-index: 10;

  @include media-breakpoint-up(sm) {
    top: -50px;
    // right: 100px;
    opacity: 0;
    transition: all 0.5s ease-out 0.5s;
  }

  @include media-breakpoint-up(xl) {
    right: 100px;
  }

  @media(min-width: 1500px) {
    right: 8vw;
  }

  &.scrolled {
    @include media-breakpoint-up(sm) {
      opacity: 1;
      top: 30px;
    }
  }

  button {
    padding: 0;
    transform: rotate(-90deg);
    transition: transform 0.2s;

    &.rotate {
      transform: rotate(-180deg);
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    img {
      width: 30px;

      @include media-breakpoint-up(sm) {
        width: 45px;
      }

      @include media-breakpoint-up(xl) {
        width: 65px;
      }
    }
  }

  ul {
    font-weight: 700;
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: absolute;
    top: 100%;
    right: -100vw;
    font-size: 16px;
    transition: right 0.2s;
    text-shadow: 0 0 1em $white;
    display: flex;
    flex-direction: column;
    // display: none;
    @include media-breakpoint-up(lg) {
      font-size: 25px;
      right: 0;
      flex-direction: row;
      text-shadow: unset;
    }

    &.show {
      // display: block;
      right: 0;
    }

    li {
      text-align: center;
      width: max-content;
      margin-top: 20px;
      &:first-child {
        margin-top: 40px;
      }

      @include media-breakpoint-up(lg) {
        margin-left: auto;
        border-bottom: none;
        margin-left: 50px;
        margin-top: 10px;
        &:first-child {
          margin-top: 10px;
        }
      }

      @include media-breakpoint-up(xl) {
        margin-top: 20px;
        &:first-child {
          margin-top: 20px;
        }
      }

      a {
        img {
          margin-left: 20px;
          max-width: 60px;

          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
      }

    }
  }
}