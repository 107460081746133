#fitsio {
  .banner {
    margin-bottom: 0;
  }
  #intro {
    padding: 150px 0;
    background-color: #ea0303;
    img {
      margin-bottom: 150px;
    }

    p {
      color: $white;
    }
  }
}