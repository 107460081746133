#webform {
  .field {
    margin-bottom: 30px;
    label {
      &::before {
        content: "*";
        margin-right: 5px;
      }
    }
    ::placeholder {
      color: $gray-600;
      opacity: 1; /* Firefox */
    }
  }
  .error {
    color: $red;
  }
}