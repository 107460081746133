#projects {
  .banner {
    .image {
      img {
        max-height: 550px;
      }
    }
  }
  .showcase {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 100px;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }

    .project {
      flex-basis: 100%;
      margin-bottom: 30px;
      @include media-breakpoint-up(sm) {
        flex-basis: 48%;
        margin-bottom: 20px;
      }
      @include media-breakpoint-up(xxl) {
        margin-bottom: 40px;
      }
      figure {
        .image {
          margin-bottom: 5px;
          display: inline-block;
          overflow: hidden;
          width: 100%;
          .figure-img {
            margin-bottom: 0;
            object-fit: cover;
            @include media-breakpoint-up(sm) {
              height: 140px;
            }
            @include media-breakpoint-up(md) {
              height: 200px;
            }
            @include media-breakpoint-up(lg) {
              height: 180px;
              transition: transform 0.5s ease;
              &:hover {
                transform: scale(1.1);
              }
            }
            @include media-breakpoint-up(xl) {
              height: 220px;
            }
          }
        }
   
        figcaption {
          max-width: 572px;
          font-weight: 500;
          img {
            width: 40px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}