@keyframes arrow-left {
  0% {left: 0px;}
  25% {left: -5px;}
  75% {left: 5px;}
  100% {left: 0px;}
}

#mystory {
  #about {
    background-image: url("../assets/images/my_story/cloudy.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
      overflow-x: unset;
      margin-bottom: 300px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 400px;
    }

    .wrapper {
      padding-top: 150px;
      padding-bottom: 50px;
      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }
      .content {
        background-color: $white;
        padding: 50px 20px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 100%;
          width: 100%;
          height: 100%;
          background-color: $white;

          @include media-breakpoint-up(lg) {
            position: unset;
            background-color: $gray-200;
          }
        }

        @include media-breakpoint-up(lg) {
          padding: 100px 90px 0;
          width: 50%;
          margin-left: auto;
          background-color: $gray-200;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 150px;
            background-color: $gray-200;
            z-index: 1;
          }

        }

        .title {
          font-size: 14px;
          font-weight: 900;
          margin-bottom: 50px;
          position: relative;

          @include media-breakpoint-up(lg) {
            font-size: 14px;
            width: fit-content;
          }

          &::after {
            content: "";
            width: 1.5em;
            border-top: 1px solid $dark;
            position: absolute;
            top: calc(100% + 20px);
            left: 0;
            @include media-breakpoint-up(lg) {
              width: 100%;
            }
          }
        }

        p {
          font-size: 14px;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            text-decoration: underline;
            font-weight: 700;
          }
        }
      }
    }
  }

  .resume {
    position: relative;
    &.scrolled {
      .wrapper {
        .image_wrapper {
          left: 0;
          opacity: 1;
          @include media-breakpoint-up(md) {
            left: 10%;
          }
        }
        .content {
          right: 0;
          opacity: 1;
          @include media-breakpoint-up(md) {
            right: 10%;
          }
        }
      }
    }
    .quicklink {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 900;
      margin: 50px 0;
      @include media-breakpoint-up(lg) {
        // position: absolute;
        // top: -50px;
        // left: 55%;
        // z-index: 1;
        // margin: 0;
        margin-bottom: 50px 0 0 0;
        font-size: 22px;
      }
      @include media-breakpoint-up(xl) {
        left: 60%;
      }

      img {
        margin-left: 20px;
        width: 44px;
        object-fit: contain;
        align-self: center;
        position: relative;
        left: 0;
        animation-name: arrow-left;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    .wrapper {
      position: relative;

      // .image_wrapper {
      //   position: relative;
      //   align-self: center;
      //   background-color: $white;
      //   width: 33%;
      //   text-align: end;
      //   left: -100px;
      //   opacity: 0;
      //   transition: all 0.5s;
      //   @include media-breakpoint-up(lg) {
      //     // width: 47%;
      //   }
      //   img {
      //     padding-top: 50px;
      //     background-color: $lightgrey-100;
      //     @include media-breakpoint-up(lg) {
      //       padding-top: 100px;
      //     }
      //   }

      // }

      .content {
        border: 1px solid $black;
        padding: 20px;
        // position: absolute;
        // right: -100px;
        opacity: 0;
        width: 72%;
        // top: 0;
        // bottom: 0;
        height: fit-content;
        margin-top: auto;
        margin-bottom: auto;
        transition: all 0.5s;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-up(sm) {
          width: 70%;
          padding: 40px;
        }
        @include media-breakpoint-up(md) {
          width: 50%;
          padding: 60px;
        }

        @include media-breakpoint-up(xl) {
          padding-left: 100px;
          
        }

        .name {
          font-size: 30px;
          font-weight: 900;
          margin-bottom: 30px;
          position: relative;
          font-size: 14px;

          @include media-breakpoint-up(lg) {
            width: fit-content;
            font-size: 16px;
          }

          &::after {
            content: "";
            width: 1.5em;
            border-top: 1px solid $dark;
            position: absolute;
            top: calc(100% + 15px);
            left: 0;

            @include media-breakpoint-up(lg) {
              width: 100%;
              border-top: 1px solid $dark;
            }
          }
        }

        .position {
          font-weight: 500;
          font-size: 14px;

          @include media-breakpoint-up(lg) {
            font-size: 16px;
          }
        }

        .email {
          word-break: break-all;
          font-size: 14px;
          font-weight: 300;

          @include media-breakpoint-up(lg) {
            font-size: 16px;
          }
        }

        .tel {
          font-weight: 300;
          font-size: 14px;

          @include media-breakpoint-up(lg) {
            font-size: 16px;
          }
        }
      }
    }
  }

  #my_value {
    background-color: $lightgrey-100;
    margin: 100px 0;

    .wrapper {
      padding-top: 50px;
      padding-bottom: 50px;

      .title {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {
          font-size: 22px;
        }
      }

      .slick-slider {
        .slick-slide {
          .flexbox {
            @include media-breakpoint-up(lg) {
              padding: 0 20px;
            }
            img {
              margin-bottom: 20px;
              width: 100px;
              margin-left: auto;
              margin-right: auto;
            }

            .value {
              font-weight: 800;
              font-size: 22px;
              margin-bottom: 20px;
              text-align: center;
            }

            p {
              text-align: center;
              font-size: 16px;

              @include media-breakpoint-up(lg) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}